import React, { useRef, useState } from "react";
import { Link } from "gatsby";
import { Row, Col, Layout, Card, Button, BackTop, Image } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";

import CommonHeader from "../components/common/header";
import CommonFooter from "../components/common/footer";

import {
  FreePlanList,
  ProPlanList,
  TeamPlanList,
} from "../components/plans/plans";
import { HomepageContent } from "../components/homepageLanding/sliderContent";
import { FeatureGrid } from "../components/homepageLanding/featureGrid";
// import { HeroSlider } from "../components/homepageLanding/heroSlider";
import HeroImage from "../components/homepageLanding/heroImage";

const { Content } = Layout;

const HomePage = () => {
  const [videoPaused, setVideoPaused] = useState(true);
  const vidRef = useRef(null);
  const vidRef2 = useRef(null);

  const handlePlayVideo = () => {
    if (videoPaused === true) {
      vidRef.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  const handlePlayVideo2 = () => {
    if (videoPaused === true) {
      vidRef2.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DayViewer | Online Calendar Planner &amp; Organizer</title>
        <meta
          name="description"
          content="The best online calendar planner and organizer system. Tasks, Notes, Journal and Team calendar planner for simple project &amp; work coordination and business management"
        />
      </Helmet>
      <Layout>
        <CommonHeader />
      </Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <Content className="homepage-background">
          <Col>
            <div
              style={{
                fontSize: "larger",
                background: "#b1f7df",
                padding: 10,
                textAlign: "center",
              }}
            >
              Download & Share Our Free Printable Daily Planner & Weekly
              Planner:{" "}
              <Link to="/landing/free-planners">
                <span>
                  Free Online Planner <DoubleRightOutlined />
                </span>
              </Link>
            </div>
          </Col>
          <div className="homepage-banner">
            <Col lg={12}>
              <div className="homepage-jumbotron">
                <div className="homepage-headlines">
                  {/* <h1>Powerful Online Calendar Planner &amp; Organizer</h1> */}
                  <h1
                    style={
                      typeof window !== "undefined" && window.innerWidth > 640
                        ? {
                            fontSize: "54px",
                          }
                        : { fontSize: "32px" }
                    }
                  >
                    The Best Online Daily Planner For Getting Organized
                  </h1>
                  {/* <h1>Plan Your Day, Get Organized &amp; Achieve More</h1> */}
                  <h2>
                    Calendar - Tasks - Organizer - Notes - Journal - Boards -
                    Contacts - Forms - AI Assistant
                  </h2>

                  <p>
                    <strong>DayViewer</strong> helps take the stress out of
                    daily management and boosts your effectiveness in your
                    business, professional and personal life. A solid{" "}
                    <strong>Online Calendar Planner &amp; Organizer</strong>{" "}
                    perfect for daily planning.
                    <br />
                    <br />
                    <ul>
                      <li>Easy To Use Daily Planner</li>
                      <li>Manage Tasks & Plan Events</li>
                      <li>Keep Notes Organized</li>
                      <li>Team Collaboration Calendar & Planner</li>
                      <li>No Complex Tutorials - Get Organized In Minutes!</li>
                    </ul>
                    {/* <br /> */}
                    In one app, you get a <strong>
                      Daily Planner{" "}
                    </strong>for <strong>Time Management,</strong>{" "}
                    <strong>Task Management</strong> & <strong>Notes.</strong>{" "}
                    There's much more inside the box, so get started for free
                    right away.
                  </p>
                </div>

                <div style={{ padding: "0 0 20px 0", marginTop: "-20px" }}>
                  <Button className="homepage-signup-btn">
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://app.dayviewer.com/auth/signup",
                          "_self"
                        )
                      }
                    >
                      Create My Online Planner
                    </a>
                  </Button>
                  <br />
                  <span style={{ fontSize: 12 }}>
                    Free to get started -{" "}
                    <Link to="/plans">Paid plans info here </Link>{" "}
                  </span>
                </div>
                <Link to="/landing/team-calendar">
                  <span className="business-link">
                    Need a Team Calendar? - Details Here <DoubleRightOutlined />
                  </span>
                </Link>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <HeroImage />
            </Col>
          </div>

          <Row>
            <Col xs={24}>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12}>
                  <div>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/task-list-planner-home_yghtqy.webp"
                      ></source>
                      <source
                        type="image/png"
                        srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/task-list-planner-home_yghtqy.png"
                      ></source>
                      <Image
                        src="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/task-list-planner-home_yghtqy.png"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Task Management Planner"
                        title="Online Task Management Planner"
                      />
                    </picture>
                  </div>
                </Col>
                <Col lg={12}>
                  <div style={{ padding: "4vw", color: "#373737" }}>
                    <h1>Manage Time &amp; Tasks Together In One Place.</h1>
                    <h2>
                      Reduce the number of apps you need to use together to
                      manage and plan your work.
                    </h2>
                    <p>
                      Whether you need more than a standalone online planner,
                      calendar or to-do list app, or you are looking to getting
                      started using a planner online. Using DayViewer as a
                      central time and task management system can help you to
                      transform your productivity from day one.{" "}
                    </p>
                    <h3>Benefits of using an Online Planner</h3>
                    <br />
                    <ul>
                      <li>Get More Organized &amp; Efficient</li>
                      <li>Get Reminded about Important Tasks &amp; Events</li>
                      <li>Get More Done and Achieve More.</li>
                      <li>Keep a Record of Your Daily Activity</li>
                      <li>Reduce Anxiety and Stress</li>
                      <li>Reduce Procrastination</li>
                    </ul>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Set Up Your Organizer
                        </a>
                      </Button>
                    </div>
                    <Link to="/landing/team-calendar">
                      <span className="business-link">
                        Need A Shared Online Team Calendar?{" "}
                        <DoubleRightOutlined />
                      </span>
                    </Link>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12}>
                  <div style={{ padding: "4vw", color: "#373737" }}>
                    <h1>DayViewer Online Planner Features</h1>

                    <p>
                      Everything is built in, so no complex setup or
                      dependencies on third party apps. Simply setup your online
                      calendar to your preferences, add your tasks and manage
                      your time with clarity.
                    </p>

                    <h3>Inside DayViewer Online Planner</h3>
                    <ul>
                      <li>Calendar with Day, Week and Month Views</li>
                      <li>Task Management</li>
                      <li>Push Notification System and Email Reminders</li>
                      <li>Dashboard, Timeline and Table Views</li>
                      <li>
                        Fast Planner System for better Scheduling Regular Tasks
                        or Events
                      </li>
                      <li>
                        Journal System - Write Content, Documents or a Daily Log{" "}
                      </li>
                      <li>
                        Notes System - Very Useful for Learning &amp;
                        Information Recall
                      </li>
                      <li>Payments &amp; Expenses Tracking</li>
                      <li>
                        Daily Todo List System - Simple Todo List System with
                        counter and rollover
                      </li>
                      <li>Contacts Management System</li>
                      <li>Label Management System</li>
                      <li>
                        Boards - For collecting and grouping information
                        together.
                      </li>
                      <li>
                        Forms - Easy to use form creation built in, create
                        internal workflows or shareable forms for your audience.
                      </li>
                      <li>
                        AI Assistant - Get information on anything by asking or
                        use as a content creation system, summarizer, translator
                        or a code helper!{" "}
                      </li>
                    </ul>
                    <p>
                      Adapts to your device or phone - with similar
                      functionality as the web version.
                    </p>

                    <p>
                      Free to sign up - plan daily for a few days to see how
                      DayViewer online planner works for you.
                    </p>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Daily Planner
                        </a>
                      </Button>
                    </div>
                    <Link to="/landing/team-calendar">
                      <span className="business-link">
                        DayViewer as a Team Calendar Planner{" "}
                        <DoubleRightOutlined />
                      </span>
                    </Link>
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/online-calendar-home_a03dmc.webp"
                      ></source>
                      <source
                        type="image/png"
                        srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/online-calendar-home_a03dmc.png"
                      ></source>
                      <Image
                        style={{
                          height: "40vw",
                          objectFit: "none",
                          objectPosition: "30% 23%",
                        }}
                        src="https://res.cloudinary.com/dayviewer1/image/upload/v1669048183/online-calendar-home_a03dmc.png"
                        alt="Online Calendar"
                        title="Online Calendar"
                      />
                    </picture>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12}>
                  <div>
                    <video
                      ref={vidRef2}
                      onClick={handlePlayVideo2}
                      preload="none"
                      controls="controls"
                      poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                      width="100%"
                      name="DayViewer Online Calendar Planner"
                      src="https://res.cloudinary.com/dayviewer1/video/upload/v1669253833/Fast_Planner_Assign_Tasks_ynnpz0.mp4"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div style={{ padding: "4vw", color: "#373737" }}>
                    <h1>Plan Efficiently With Fast Planner</h1>

                    <p>
                      The easy to use Fast Planner system will make planning
                      your week a breeze. Simply set-up your task templates and
                      drag and drop them into your day to schedule as needed. It
                      is also an interface where you can adjust task details
                      more efficiently and assign tasks easily if you are
                      scheduling for other team members.
                    </p>

                    <h3>An Easier To Use Online Calendar Planner </h3>
                    <br />
                    <ul>
                      <li>Drag &amp; Drop Interface</li>
                      <li>Save Time Filling Out Information Using Templates</li>
                      <li>Recur or Copy Entries</li>
                      <li>
                        Create Rich Document Style Calendar Entries with Images{" "}
                      </li>
                      <li>Attach Files To Your Calendar</li>
                      <li>View Your Week Clearly From Anywhere</li>
                      <li>
                        Not Just An Online Calendar - DayViewer Is A Complete
                        Online Organizer
                      </li>
                    </ul>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Weekly Planner
                        </a>
                      </Button>
                    </div>
                    <Link to="/landing/team-calendar">
                      <span className="business-link">
                        DayViewer as a Team Calendar <DoubleRightOutlined />
                      </span>
                    </Link>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12}>
                  <div style={{ padding: "4vw", color: "#373737" }}>
                    <h1>Online Calendar Planner For Events</h1>

                    <p>
                      Got contacts you need to meet with regularly, or maybe
                      they are suppliers or business associates you need to
                      regularly stay in the loop with. Create online meetings
                      and events easily and let people know about it with a
                      shareable link.
                    </p>

                    <h3>Event Management Simplified</h3>
                    <ul>
                      <li>Create Shareable Events</li>
                      <li>Schedule Meetings Easily (Virtual or IRL)</li>
                      <li>Copy / Recur Regular Events</li>
                      <li>
                        Attach More Information Like Images, or Location (links
                        to maps automatically)
                      </li>
                      <li>
                        Attach Forms Easily - Build Your Own Booking System
                      </li>
                      <li>Perfect For Internal Company / Bookings Use</li>
                      <li>Great For Friends & Family Events</li>
                      <li>Share Event Links on Social Media, Chat or Email</li>
                    </ul>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Shareable Events
                        </a>
                      </Button>
                    </div>
                    <Link to="/landing/team-calendar">
                      <span className="business-link">
                        DayViewer as a Team Calendar Planner{" "}
                        <DoubleRightOutlined />
                      </span>
                    </Link>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <video
                      ref={vidRef}
                      onClick={handlePlayVideo}
                      style={{ cursor: "pointer" }}
                      preload="none"
                      controls="controls"
                      poster="https://res.cloudinary.com/dayviewer1/image/upload/v1669048751/dv-events-create-poster_hj1res.png"
                      // autoplay="autoplay"
                      // loop="loop"
                      width="100%"
                      name="DayViewer Online Calendar Planner"
                      src="https://res.cloudinary.com/dayviewer1/video/upload/v1669048152/DayViewer_Event_Create_vzm3ah.mp4"
                    />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12}>
                  <div style={{ padding: "4vw", color: "#373737" }}>
                    <h1>Create Shareable or Internal Online Forms</h1>

                    <p>
                      Online forms are attachable to any entry type and can be
                      used to create workflows internally or shared via a link
                      to anyone. A great way to interact with clients or event
                      attendees (For example: ask your event attendees about
                      dietary preferences, fill out a quick survey, or give
                      feedback). Results can be viewed in a table or downloaded
                      to CSV file.
                    </p>
                    <p>
                      Forms can be cloned and edited easily so variations would
                      be great for A/B test surveys.
                    </p>

                    <h3>Here's How Forms Can Be Useful</h3>
                    <ul>
                      <li>Collaborate And Use Forms In Your Team</li>
                      <li>Create Surveys, Quizzes</li>
                      <li>
                        Increase brand awareness, traffic to your product or
                        service or business leads
                      </li>
                      <li>A Mini Internal Database</li>
                      <li>Get A Notification By Email When Form Filled</li>
                      <li>Work Processes</li>
                      <li>Create Star Rating Systems For anything</li>
                      <li>Get Feedback From Potential Clients</li>
                      <li>
                        Task Management and Job Feedback / Confirmations For
                        Contractors
                      </li>
                      <li>
                        Friendlier Appointment Time Picker When Combined With
                        Events - See this{" "}
                        <a
                          href="https://www.youtube.com/watch?v=76sE3FjfVmU"
                          target="_blank"
                        >
                          fun video example
                        </a>
                      </li>

                      <li>Reviews and Task Feedback Internally</li>
                      <li>Guest or Employee Satisfaction Surveys</li>
                    </ul>
                    <p>
                      Adapts to your device or phone - with the same
                      functionality as the web version.
                    </p>

                    <Link to="https://app.dayviewer.com/b/tEqKJ7NsFopcu9mX6B6C">
                      <span className="business-link">
                        More Information On Forms <DoubleRightOutlined />
                      </span>
                    </Link>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Daily Planner
                        </a>
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://ik.imagekit.io/dvimages/screenshot-form-example.webp"
                      ></source>
                      <source
                        type="image/png"
                        srcSet="https://ik.imagekit.io/dvimages/screenshot-form-example.png"
                      ></source>
                      <Image
                        style={{
                          height: "40vw",
                          objectFit: "none",
                          objectPosition: "10% 23%",
                        }}
                        src="https://ik.imagekit.io/dvimages/screenshot-form-example.png"
                        alt="Online Form Creator"
                        title="Online Form Creator"
                      />
                    </picture>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <div style={{ display: "flex" }}>
              <FeatureGrid />
            </div>
          </Row>
        </Content>
      </Layout>
      <Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export default HomePage;
