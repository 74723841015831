import React from "react";
import { Col, Row } from "antd";

class ContentReminders extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.webp"
              ></source>
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.jpg"
              ></source>
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.jpg"
                className="homepage-image"
                alt="online tasks reminders"
                title="online tasks reminders"
                width="100%"
                height="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Reminders &amp; Recurring Entries</h2>

            <p>
              If you find that you are forgetting important events or task
              deadlines that happen on a regular basis, setting timely reminders
              in your calendar will benefit you by alerting you what is coming
              up on your agenda.
            </p>
            <p>
              If, for example, you have a weekly briefing, simply set it up as a
              calendar entry, with the reminder period you desire - then recur
              the entry (pro), every week. This makes planning more efficient
              and gives you less to think about feeing you to do other things.
            </p>
            <p>
              The reminders will go to your signed up email address and (if
              subscribed to notifications) catch you on the go on your phone or
              tablet - never miss a thing again!
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentReminders;
