import React from "react";
import { Col, Row } from "antd";

class ContentPersonal extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/personal-organizer-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/personal-organizer-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/personal-organizer-640.jpg"
                className="homepage-image"
                alt="online personal organizer"
                title="online personal organizer"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Online Personal Organizer</h2>
            <p />
            <p>
              DayViewer makes a solid online personal organizer system. Keeping
              information together is more efficient than using multiple
              separate productivity tools. As an online calendar planner and
              notes system, DayViewer helps you plan your time, tasks, notes and
              more keeping all your information organized and together in one
              place.
            </p>
            <p>
              DayViewer is designed to cater for busy individuals and people who
              need to get things done. No matter the size or complexity of the
              task. The simple action of writing things down and keeping
              information together provides a way for you to achieve more.
            </p>
            <p>
              DayViewer online calendar planner gets thousands of professionals,
              students and busy people organized worldwide.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentPersonal;
