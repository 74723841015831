import React from "react";

const HeroImage = () => {
  return (
    <div>
      <picture>
        <source
          type="image/webp"
          srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1662550777/online_calendar_planner_home_8420ebc52e.webp"
        ></source>
        <source
          type="image/png"
          srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1662550777/online_calendar_planner_home_8420ebc52e.png"
        ></source>
        <img
          src="https://res.cloudinary.com/dayviewer1/image/upload/v1662550777/online_calendar_planner_home_8420ebc52e.png"
          height="100%"
          width="100%"
          alt="Online Calendar Planner"
          title="Online Calendar Planner"
        />
      </picture>
    </div>
  );
};

export default HeroImage;
