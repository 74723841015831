import React, { Component } from "react";
import { List, Card } from "antd";
import {
  BookOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  MessageOutlined,
  PlusOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

const data = [
  {
    title: (
      <span>
        <CalendarOutlined /> Calendar
      </span>
    ),
    content:
      "At the heart of DayViewer is the Online Calendar. With a variety of views inculding Monthly, Weekly, Daily & Agenda. View your schedule, set reminders and plan daily.",

    url: "/dayviewer.com",
  },
  {
    title: (
      <span>
        <PushpinOutlined /> Notes
      </span>
    ),
    content:
      "DayViewer contains a handy Online Notes system that lets you save rich notes, checklists and other information so you can refer to them whenever required.",
  },
  {
    title: (
      <span>
        <CheckSquareOutlined /> Tasks
      </span>
    ),
    content:
      "DayViewer Online Task Management system lets you easily create and manage tasks. Tasks have priority and progress attributes. Tasks appear in you Calendar so you receive reminders (if set).",
  },
  {
    title: (
      <span>
        <BookOutlined /> Journal
      </span>
    ),
    content:
      "The Online Journal gives you a space to write documents and articles, or just to record your days. Journal Posts can be shared online should you wish to do so.",
  },
  {
    title: (
      <span>
        <ContactsOutlined /> Contacts
      </span>
    ),
    content:
      "Easy to use contact management system. Keep notes of your important contacts and attach them to calendar entries for efficiency in planning meetings.",
  },
  {
    title: (
      <span>
        <PlusOutlined /> Much More (Pro)
      </span>
    ),
    content:
      "Additionally if you go Pro many features, enhancements and views are unlocked, including, a payments tracker, labels manager, a todo list system and dashboard.",
  },
];

class FeatureGrid extends Component {
  render() {
    return (
      <Card className="feature-grid-background" bordered={false}>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 6,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.title}
                // bordered={false}
                className="feature-grid-cards"
              >
                {item.content}{" "}
                {/* <p>
                  <a href={item.url}>Screencast</a>
                </p> */}
              </Card>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

export { FeatureGrid };
