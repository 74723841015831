import React, { Component } from "react";
import { Card } from "antd";
import Slider from "react-slick";

import ContentPersonal from "../../pages/homepage-content/contentPersonal";
import ContentLearning from "../../pages/homepage-content/contentLearning";
import ContentLists from "../../pages/homepage-content/contentLists";
import ContentReminders from "../../pages/homepage-content/contentReminders";
import ContentJournal from "../../pages/homepage-content/contentJournal";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",
        height: "100px",
        right: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <RightOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",
        height: "100px",
        left: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <LeftOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

class HomepageContent extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <Card
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: 10,
          width: "100%",
          background: "#ffffff",
          border: "none",
        }}
        title="See how DayViewer helps thousands every day"
      >
        <div>
          <Slider {...settings}>
            <div>
              <ContentPersonal />
            </div>
            <div>
              <ContentLearning />
            </div>
            <div>
              <ContentLists />
            </div>
            <div>
              <ContentReminders />
            </div>
            <div>
              <ContentJournal />
            </div>
          </Slider>
        </div>
      </Card>
    );
  }
}

export { HomepageContent };
