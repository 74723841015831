import React from "react";
import { Col, Row } from "antd";

class ContentLearning extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/study-planner-640.webp"
              ></source>
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/study-planner-640.jpg"
              ></source>
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/study-planner-640.jpg"
                className="homepage-image"
                alt="online study planner"
                title="online study planner"
                width="100%"
                height="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Online Study Planner</h2>
            <p />
            <p>
              Use DayViewer Online Planner to help you study and learn anything.
              Keep your notes organized with our notes organizer. Plan your
              class and lecture timetables. Plan revision times strategically.
              Use journal pages to help you attach research notes, images,
              documents and articles. Linking entries with labels will give you
              fast access to all the information you need according to subject.
            </p>
            <p>
              DayViewer is used by students and professionals around the world
              who need to stay on top of their studies and keep information
              manageable.
            </p>
            <p>
              The free plan will give you a taster of how you can use DayViewer
              as an online study planner, and the pro plan will ultimately
              enable you to achieve your study goals and help you get top of the
              class.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentLearning;
